import React from 'react'
import { graphql } from 'gatsby'
import { hasWindow } from '../util/dom'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  Map,
  AttributionControl,
  CircleMarker,
  LayersControl,
  TileLayer,
} from 'react-leaflet'
import parse from 'wellknown'
import { QRCode } from 'react-qr-svg'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import GmapIcon from '../assets/gmap-icon.svg'
import './apiary.css'

export default ({ data }) => {
  // if there is no window, we cannot render this component
  if (!hasWindow) {
    return null
  }

  // https://github.com/PaulLeCam/react-leaflet/blob/master/example/components/layers-control.js
  const { BaseLayer, Overlay } = LayersControl

  const apiary = data.apiariesCsv

  // Join address fields.
  let address = [
    apiary.aadr_aadress,
    apiary.aadr_kyla_alev,
    apiary.aadr_valdlinn_tekst,
    apiary.aadr_maakond_tekst,
  ]
    .filter(function(item) {
      return item != null
    })
    .join(', ')

  // Join phone fields.
  let phone = [apiary.reg_mobiil, apiary.reg_telefon]
    .filter(function(item) {
      return item != null
    })
    .join(' ')

  // Convert WKT points to JSON lat, lng using wellknown library.
  let geometry = parse(apiary.geometry)
  const lat = geometry['coordinates'][0]
  const lng = geometry['coordinates'][1]
  const mapZoom = '15'
  const mapLayer = 'k'
  const mapSearch = 'yp'

  return (
    <Layout>
      <SEO
        title={apiary.pohi_nr + ' ' + apiary.aadr_aadress + ' mesila'}
        lang="et"
        keywords={[apiary.pohi_nr, `mesi`, `honey`]}
        description={address}
      />
      <Row>
        <Col>
          <h3>
            {apiary.pohi_nr} {apiary.aadr_aadress} mesila
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Text>
                <strong>Mesiniku telefon: </strong>
                {phone}
                <br />
                <strong>PRIA's alates: </strong>
                {apiary.reg_kp}
                <br />
                <strong>Aadress: </strong>
                {address}
                <br />
                {/* https://stackoverflow.com/a/30726687/10685817 */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://maps.google.com/maps?&z=${mapZoom}&mrt=${mapSearch}&t=${mapLayer}&q=${lng}+${lat}`}
                >
                  <GmapIcon height="24px" width="24px" />
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col id="qrcode" xs lg="2">
          <QRCode
            bgColor="#FFFFFF"
            fgColor="#ffcc00"
            level="Q"
            style={{ width: 192 }}
            value={'https://mesi.ee/apiary/' + apiary.pohi_nr}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Card>
            <Map
              key={Math.random()}
              center={[lng, lat]}
              zoom={15}
              minZoom={7}
              maxZoom={18}
              attributionControl={false}
            >
              <AttributionControl position="bottomright" prefix={false} />
              <LayersControl position="topright">
                <BaseLayer name="Kaart">
                  <TileLayer
                    attribution="Kaart: Maa-amet | Andmed: PRIA"
                    url="https://tiles.maaamet.ee/tm/tms/1.0.0/kaart@GMC/{z}/{x}/{-y}.png"
                    // attribution="Kaart: OpenStreetMap | Andmed: PRIA"
                    // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                </BaseLayer>
                <BaseLayer checked name="Foto">
                  <TileLayer
                    // attribution="Kaart: Esri | Andmed: PRIA"
                    // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    attribution="Kaart: Maa-amet | Andmed: PRIA"
                    url="https://tiles.maaamet.ee/tm/tms/1.0.0/foto@GMC/{z}/{x}/{-y}.png"
                  />
                </BaseLayer>
                <Overlay checked name="Hübriid">
                  <TileLayer
                    // url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png"
                    url="https://tiles.maaamet.ee/tm/tms/1.0.0/hybriid@GMC/{z}/{x}/{-y}.png"
                  />
                </Overlay>
                <Overlay checked name="Mesila">
                  <CircleMarker
                    key={apiary.id}
                    center={[lng, lat]}
                    fillOpacity={0.7}
                    fillColor={`#ffcc00`}
                    color={`#FFFFFF`}
                    weight={1}
                    opacity={1}
                    radius={16}
                  ></CircleMarker>
                </Overlay>
              </LayersControl>
            </Map>
          </Card>
        </Col>
      </Row>
      <br />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    apiariesCsv(pohi_nr: { eq: $slug }) {
      id
      pohi_nr
      aadr_aadress
      aadr_kyla_alev
      aadr_valdlinn_tekst
      aadr_maakond_tekst
      reg_kp(formatString: "DD. MMMM YYYY", locale: "et-EE")
      reg_mobiil
      reg_telefon
      geometry
    }
  }
`
